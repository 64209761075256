import Snackbar from 'awesome-snackbar';
import { router } from '.';

export function resNotOk(response, useAccountStore, setUser) {
	if (response.status === 401) {
		// useAccountStore.getState().logout();
		useAccountStore.getState().closeAllModals();

		new Snackbar('Sesión expirada / No autorizado', {
			style: {
				container: [
					['border-radius', '10px'],
					['background', 'white']
				],
				message: [
					['color', 'red'],
					['font-size', '14px']
				]
			}
		});

		setUser(null);
		router.navigate('/');

		// router.navigate('/');
		throw new Error('Sesión expirada / No autorizado');
	} else {
		throw new Error(response.message);
	}
}

export function Export2Doc(planiationToEdit) {
	console.log("🚀 ~ Export2Doc ~ planiationToEdit:", planiationToEdit);

    const planiationText = planiationToEdit?.planiation?.planiation ?? planiationToEdit?.planiation ?? "";

	const planiationMetadata = JSON.parse(
		planiationToEdit?.planiation?.planiation_metadata ?? '{}'
	);
	let filename = planiationMetadata?.opportunity ?? 'Planeacion';

	if (planiationText?.length === 0) {
		return;
	}

	var preHtml =
		"<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
	var postHtml = '</body></html>';

	var html = preHtml + planiationText + postHtml;

	var blob = new Blob(['\ufeff', html], {
		type: 'application/msword'
	});

	var url =
		'data:application/vnd.ms-word;charset=utf-8,' +
		encodeURIComponent(html);

	filename = filename ? filename + '.doc' : 'document.doc';

	var downloadLink = document.createElement('a');

	document.body.appendChild(downloadLink);

	if (navigator.msSaveOrOpenBlob) {
		navigator.msSaveOrOpenBlob(blob, filename);
	} else {
		downloadLink.href = url;
		downloadLink.download = filename;
		downloadLink.click();
	}

	document.body.removeChild(downloadLink);
}
