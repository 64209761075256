import { create } from 'zustand';
import useAccountStore from './useAccountStore';
import { resNotOk } from '../utils';

const usePlanningsStore = create((set) => ({
	plannings: [],
	loadingPlaniation: false,
	loadingPlaniations: false,
	loadingUpdatePlaniation: false,
	planiationToEdit: null,
	setLoadingPlaniation: (value) => set(() => ({ loadingPlaniation: value })),
	setPlannings: (plannings) => set({ plannings }),
	getPlaniations: async (page) => {
		set(() => ({ loadingPlaniations: true }));
		try {
			const limit = 10;
			const offset = (page * limit) ?? 0;
			const url =
				process.env.REACT_APP_API_HOST +
				`/plannings?offset=${offset}&limit=${limit}`;

			const response = await fetch(url, {
				method: 'GET',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
					/* Authorization: useAccountStore.getState().user.googleToken */
				}
			});

			if (!response.ok) {
				resNotOk(response, useAccountStore, useAccountStore.getState().setUser);
			}

			const data = await response.json();

			set(() => ({ plannings: data.rows }));
			
			return data.count;
		} catch (e) {
			set(() => ({ plannings: []}));
			console.error(e);
		} finally {
			set(() => ({ loadingPlaniations: false }));
		}
	},
	setPlaniation: async (planiationToEdit) =>
		set({ planiationToEdit: planiationToEdit }),
	getPlaniation: async (id) => {
		set(() => ({ loadingPlaniation: true }));

		try {
			const url = process.env.REACT_APP_API_HOST + '/plannings/' + id;
			const response = await fetch(url, {
				method: 'GET',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
					/* Authorization: useAccountStore.getState().user.googleToken */
				}
			});

			if (!response.ok) {
				resNotOk(response, useAccountStore, useAccountStore.getState().setUser);
			}
			const data = await response.json();

			set(() => ({ planiationToEdit: data }));
		} catch (e) {
			console.error(e);
		} finally {
			set(() => ({ loadingPlaniation: false }));
		}
	},
	generatePlanning: async (planniation) => {
		set(() => ({ loadingPlaniation: true }));
		try {
			const url = process.env.REACT_APP_API_HOST + '/plannings/generate';
			const response = await fetch(url, {
				method: 'POST',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
					/* Authorization: useAccountStore.getState().user.googleToken */
				},
				body: JSON.stringify(planniation)
			});

			if (!response.ok) {
				resNotOk(response, useAccountStore, useAccountStore.getState().setUser);
			}
			const data = await response.json();
			// console.log('🚀 ~ generatePlanning: ~ response:', data);
			return (await data?.planiation) ?? 0;
			//set((state) => ({ plannings: [...state.plannings, planning] }));
		} catch (e) {
			console.error(e);
		} finally {
			set(() => ({ loadingPlaniation: false }));
			useAccountStore.getState().getTokens();
		}
	},
	updatePlanningContent: async (planiation, newPlaniationContent) => {
		set(() => ({ loadingUpdatePlaniation: true }));
		try {
			const url =
				process.env.REACT_APP_API_HOST + '/plannings/update-content';
			const response = await fetch(url, {
				method: 'POST',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
					/* Authorization: useAccountStore.getState().user.googleToken */
				},
				body: JSON.stringify({
					id: planiation.id,
					planiation: newPlaniationContent
				})
			});

			if (!response.ok) {
				resNotOk(response, useAccountStore, useAccountStore.getState().setUser);
			}

			const data = await response.json();

			console.log('🚀 ~ updatePlanningContent: ~ data:', data);
		} catch (e) {
			console.error(e);
		} finally {
			set(() => ({ loadingUpdatePlaniation: true }));
		}
	}
}));

export default usePlanningsStore;
