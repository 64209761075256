import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAccountStore from '../../stores/useAccountStore';
import paymentImage from '../../assets/payment-success.svg';
import * as moment from 'moment';
import 'moment/locale/es-mx';

const Return = () => {
	const { user, getSessionStatus, getTokens, loadingPaymetStatus } =
		useAccountStore();

	const navigate = useNavigate();

	moment.locale('es-mx');

	// const [status, setStatus] = useState(null);
	const [payment, setPayment] = useState(null);
	const [customerEmail, setCustomerEmail] = useState('');

	const tokensFlagRef = useRef(false);

	useEffect(() => {
		async function getStatus() {
			if (tokensFlagRef.current) return;
	
			const data = await getSessionStatus();
	
			getTokens();
	
			setPayment(data);
			setCustomerEmail(data?.customer_email);
	
			if (data) {
				tokensFlagRef.current = true;
			}
		}

		getStatus();
	}, [user, getSessionStatus, getTokens]);

	useEffect(() => {
		if (payment?.status === 'open') {
			navigate('/');
		}
	}, [payment, navigate]);


	/* if (status === 'complete') {
		return (
			<section id="success">
				<p>
					We appreciate your business! A confirmation email will be
					sent to {customerEmail}. If you have any questions, please
					email{' '}
					<a href="mailto:orders@example.com">orders@example.com</a>.
				</p>
			</section>
		);
	} */

	return (
		<div className="py-7 max-w-[400px] m-auto">
			{loadingPaymetStatus ? (
				<div role="status" className="animate-pulse">
					<div className="h-10 mb-7 bg-neutral-400 opacity-50 rounded-xl dark:bg-gray-700 w-full"></div>
					<div className="h-8 mb-2 bg-neutral-400 opacity-50 rounded-xl dark:bg-gray-700 w-full"></div>
					<div className="h-64 mb-2 bg-neutral-400 opacity-50 rounded-xl dark:bg-gray-700 w-full"></div>
					<div className="h-10 mb-3 bg-neutral-400 opacity-50 rounded-xl dark:bg-gray-700 w-full"></div>
					<div className="h-12 mb-3 bg-neutral-400 opacity-50 rounded-xl dark:bg-gray-700 w-full"></div>

					<div className="h-5 mb-1 bg-neutral-400 opacity-50 rounded-xl dark:bg-gray-700 w-full"></div>
					<div className="h-5 mb-1 bg-neutral-400 opacity-50 rounded-xl dark:bg-gray-700 w-full"></div>
					<div className="h-5 mb-2 bg-neutral-400 opacity-50 rounded-xl dark:bg-gray-700 w-full"></div>
					<div className="h-10 mb-2 bg-neutral-400 opacity-50 rounded-xl dark:bg-gray-700 w-[80%] m-auto"></div>
					<div className="h-10 mb-2 bg-button-main opacity-50 rounded-xl dark:bg-gray-700 w-[50%] m-auto"></div>

					<span className="sr-only">Cargando...</span>
				</div>
			) : (
				<>
					<h1 className="text-center font-semibold text-4xl ">
						Estado de pago
					</h1>

					{payment?.status === 'complete' ? (
						<div className="mt-7">
							<h3 className="text-center font-semibold text-2xl text-green-700">
								Pago exitoso!
							</h3>
							<img
								src={paymentImage}
								alt="Payment ilustration"
								className="m-auto h-64"
							/>
							<p className="mt-5 text-center text-sm">
								¡Gracias por tu compra! Un correo de
								confirmación será enviado a{' '}
								<span className="text-blue-500">
									{customerEmail}
								</span>
								.
							</p>
							<p className="text-5xl text-center my-5">
								${(payment?.amount / 100).toFixed(2)}{' '}
								{payment.currency}
							</p>
							<hr className="border-zinc-700 mb-5" />
							<div className="text-sm">
								<div className="flex justify-between items-center">
									<p className="font-bold">Fecha: </p>
									<p>{moment().format('LLL')}</p>
								</div>
								<div className="flex justify-between items-center">
									<p className="font-bold">Subtotal: </p>
									<p>
										${(payment?.sub_total / 100).toFixed(2)}{' '}
										{payment.currency}
									</p>
								</div>
								<div className="flex justify-between items-center">
									<p className="font-bold">Total: </p>
									<p>
										${(payment?.amount / 100).toFixed(2)}{' '}
										{payment.currency}
									</p>
								</div>
							</div>

							<p className="text-center text-xs mt-2">
								Si tienes alguna pregunta, por favor envía un
								correo a{' '}
								<a
									href="mailto:pagos@planeacionai.com"
									className="text-blue-500 underline"
								>
									pagos@planeacionai.com
								</a>
								.
							</p>
							<button
								className="text-sm bg-button-main rounded-lg text-white px-8 py-2 flex items-center gap-2 w-fit m-auto mt-5"
								onClick={() => {
									navigate('/');
								}}
							>
								← Ir al inicio
							</button>
						</div>
					) : (
						<div className="mt-7">
							<h3 className="text-center font-semibold text-2xl">
								Error en el pago
							</h3>
							<p className="text-center text-xs mt-2">
								Si tienes alguna pregunta, por favor envía un
								correo a{' '}
								<a
									href="mailto:pagos@planeacionai.com"
									className="text-blue-500 underline"
								>
									pagos@planeacionai.com
								</a>
								.
							</p>
							<button
								className="text-sm bg-button-main rounded-lg text-white px-8 py-2 flex items-center gap-2 w-fit m-auto mt-5"
								onClick={() => {
									navigate('/');
								}}
							>
								← Ir al inicio
							</button>
						</div>
					)}
				</>
			)}
		</div>
	);
};

export default Return;
