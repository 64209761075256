import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Return from './pages/Return';
import reportWebVitals from './reportWebVitals';
import {
	Outlet,
	Route,
	RouterProvider,
	createBrowserRouter,
	createRoutesFromElements
} from 'react-router-dom';
import useAccountStore from './stores/useAccountStore';
import Header from './components/Header';
import Edit from './pages/Edit';
import Modal from './components/Modal';
import Login from './components/Login';
import Register from './components/Register';
import NotVerified from './components/NotVerified';
import MyAccount from './components/MyAccount';
import AddPayementMethod from './components/AddPayementMethod';
import BuyTokens from './components/BuyTokens';
import Planiations from './components/Planiations';
import usePlanningsStore from './stores/usePlanningsStore';
import GeneratingPlaniationLoading from './components/GeneratingPlaniationLoading';
import { AnimatePresence } from 'framer-motion';
import UnderDevelpment from './components/UnderDevelpment';

export const router = createBrowserRouter(
	createRoutesFromElements(
		<Route element={<Main />}>
			<Route path="/" element={<App />} />
			<Route path="/edit-planiation/:id" element={<Edit />} />
			<Route path="/return" element={<Return />} />
			<Route
				path="*"
				element={
					<>
						<h1>Error 404</h1>
						<h2>Pagina no encontrada</h2>
					</>
				}
			/>
		</Route>
	)
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

function Main() {
	const {
		user,
		openLogin,
		setOpenLogin,
		openRegister,
		openMyAccount,
		openBuyTokens,
		openAddPaymentMethod,
		setOpenAddPaymentMethod,
		setOpenMyAccount,
		setOpenRegister,
		setOpenBuyTokens,
		setOpenPlaniations,
		openPlaniations,
		loginFromLocalStorage
	} = useAccountStore();

	const { setPlannings, loadingPlaniation } = usePlanningsStore();

	useEffect(() => {
		// CHECK IF WE HAVE A USER IN LOCAL STORAGE
		const user = loginFromLocalStorage(router);
		if (!user) {
			router.navigate('/');
		}

		// CHECK IF WE HAVE A EMAIL LINK TO LOGIN
		// loginWithEmailLink();
	}, [loginFromLocalStorage]);

	function handleSetOpenPlaniations(value) {
		if (value) {
			setOpenPlaniations(value);
		} else {
			// SET THE PLANIATIONS TO []
			setPlannings([]);
			setOpenPlaniations(value);
		}
	}

	return (
		<div className="App font-montserrat bg-[#EBEBEB] text-[#333333] text-start">
			{process.env.REACT_APP_ENVIRONMENT === 'DEVELOPMENT' ? (
				<>
					<Header />
					<div className="max-w-7xl m-auto px-8 xl:px-0">
						<AnimatePresence initial={false}>
							<Outlet />
						</AnimatePresence>
					</div>
					{/* GENERATING PLANIATION MODAL */}
					<Modal
						width={'auto'}
						open={loadingPlaniation}
						setOpen={() => {}}
					>
						<GeneratingPlaniationLoading />
					</Modal>
					{/* MY PLANIATIONS MODAL */}
					<Modal
						width={'auto'}
						open={openPlaniations}
						setOpen={handleSetOpenPlaniations}
					>
						<Planiations />
					</Modal>
					{/* LOGIN MODAL */}
					<Modal
						width={'auto'}
						open={openLogin}
						setOpen={setOpenLogin}
					>
						<Login
							setOpenLogin={setOpenLogin}
							setCloseRegister={setOpenRegister}
						/>
					</Modal>
					{/* REGISTER MODAL */}
					<Modal
						width={'auto'}
						open={openRegister}
						setOpen={setOpenRegister}
					>
						<Register
							setOpenLogin={setOpenLogin}
							setCloseRegister={setOpenRegister}
						/>
					</Modal>
					{/* NOT EMAIL VERIFED MODAL */}
					<Modal
						width={'auto'}
						open={user ? !user?.emailVerified : false}
						setOpen={() => {}}
					>
						<NotVerified />
					</Modal>
					{/* MY ACCOUNT MODAL */}
					<Modal
						width={'auto'}
						open={openMyAccount}
						setOpen={() => {
							setOpenMyAccount(false);
						}}
					>
						<MyAccount />
					</Modal>
					{/* ADD PAYMENT METHOD */}
					<Modal
						width={'auto'}
						open={openAddPaymentMethod}
						setOpen={() => {
							setOpenAddPaymentMethod(false);
						}}
					>
						<AddPayementMethod />
					</Modal>
					{/* BUY TOKENS*/}
					<Modal
						width={'auto'}
						open={openBuyTokens}
						setOpen={() => {
							setOpenBuyTokens(false);
						}}
					>
						<BuyTokens />
					</Modal>
				</>
			) : (
				<UnderDevelpment />
			)}
		</div>
	);
}
