import {
	EmbeddedCheckout,
	EmbeddedCheckoutProvider
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useCallback } from 'react';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function BuyTokens() {

	const fetchClientSecret = useCallback(() => {
		try {
			return fetch(
				process.env.REACT_APP_API_HOST +
					'/payments/create-checkout-session',
				{
					method: 'POST',
					credentials: 'include',
					headers: {
						'Content-Type': 'application/json',
						// Authorization: user.googleToken
					}
				}
			)
				.then((res) => res.json())
				.then((data) => data.clientSecret);
		} catch (e) {
			console.error(e);
		}
		// Create a Checkout Session
	}, []);

	const options = { fetchClientSecret };

	return (
		<div id="checkout" className="max-w-full sx:w-96">
            <h3 className="text-center font-semibold text-2xl w-[300px]">Comprar tokens</h3>
			<EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
				<EmbeddedCheckout />
			</EmbeddedCheckoutProvider>
		</div>
	);
}

export default BuyTokens;
