import { Tooltip } from 'react-tooltip';
import aiIcon from '../../assets/ai-icon.svg';
import settingsIcon from '../../assets/settings.svg';
import usePlanningsStore from '../../stores/usePlanningsStore';
import useAccountStore from '../../stores/useAccountStore';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Snackbar from 'awesome-snackbar';

function useGenerateForm() {
	const { generatePlanning } = usePlanningsStore();

	const navigate = useNavigate();

	async function sendForm(e) {
		const fields = new FormData(e.target);
		const formValues = Object.fromEntries(fields);

		console.log('🚀 ~ sendForm ~ formValues', formValues);

		if (formValues?.opportunity?.length === 0) {
			new Snackbar(
				'Es necesario agregar una problemática u oportunidad',
				{
					style: {
						container: [
							['border-radius', '10px'],
							['background', 'white']
						],
						message: [
							['color', 'red'],
							['font-size', '14px']
						]
					}
				}
			);

			return;
		}

		// SEND THIS VALUES TO THE API
		const planiation_id = await generatePlanning(formValues);
		navigate('edit-planiation/' + planiation_id);
	}

	return [sendForm];
}

function GenerateForm() {
	const {
		user,
		tokens,
		setOpenLogin,
		setOpenRegister,
		setOpenMyAccount,
		setOpenBuyTokens
	} = useAccountStore();
	const { loadingPlaniation } = usePlanningsStore();

	const [selectedLevel, setSelectedLevel] = useState('Preescolar');

	const [sendForm] = useGenerateForm();

	const grades = useRef({
		Primaria: [
			'Primero',
			'Segundo',
			'Tercero',
			'Cuarto',
			'Quinto',
			'Sexto'
		],
		Secundaria: ['Primero', 'Segundo', 'Tercero'],
		Preescolar: ['Primero', 'Segundo', 'Tercero']
	});

	return (
		<form
			className="mt-3 lg:mt-8 flex flex-col w-full"
			onSubmit={(e) => {
				e.preventDefault();
				sendForm(e);
			}}
		>
			<label
				htmlFor="grade"
				className="font-semibold text-sm lg:text-base block text-start"
			>
				Seleccione un nivel y grado
			</label>

			{tokens <= 0 && user && (
				<Tooltip anchorSelect=".input" place="top" clickable>
					<p className="text-xs text-center">
						Para generar una planeación, necesitas tener tokens
						disponibles.
						<br />
						<span
							className="text-xs cursor-pointer underline"
							onClick={() => setOpenMyAccount(true)}
						>
							Compra mas tokens aquí.
						</span>
					</p>
				</Tooltip>
			)}
			<div className="flex gap-2">
				<select
					disabled={user?.tokens <= 0}
					name="level"
					id="level"
					className="bg-input-bg rounded-lg p-2 mt-2 input w-full text-xs lg:text-sm"
					onChange={() => {
						setSelectedLevel(
							document.getElementById('level').value
						);
					}}
				>
					<option disabled>Seleccione</option>
					<option value="Preescolar">Preescolar</option>
					<option value="Primaria">Primaria</option>
					<option value="Secundaria">Secundaria</option>
				</select>
				{selectedLevel && (
					<select
						disabled={user?.tokens <= 0}
						name="grade"
						id="grade"
						className="bg-input-bg rounded-lg p-2 mt-2 input w-full text-xs lg:text-sm"
					>
						<option disabled>Seleccione</option>

						{grades?.current[selectedLevel]?.map((grade) => {
							return (
								<option
									className="capitalize"
									value={grade}
									key={grade}
								>
									{grade}
								</option>
							);
						})}
					</select>
				)}
			</div>

			<label
				htmlFor="opportunity"
				className="font-semibold text-sm lg:text-base block text-start mt-3 lg:mt-8"
			>
				Problema u oportunidad de tu entorno educativo comunitario
			</label>
			<textarea
				disabled={user?.tokens <= 0}
				name="opportunity"
				id="opportunity"
				className="h-40 p-2 bg-input-bg rounded-lg mt-2 input text-xs lg:text-sm"
			></textarea>
			<label className="text-xs block text-start mt-[-22px] pl-2 opacity-30">
				* Maximo 140 caracteres
			</label>

			{user ? (
				<div className="mt-5">
					<span
						className="text-sm cursor-pointer"
						onClick={() => setOpenBuyTokens(true)}
					>
						<b>{tokens}</b> tokens disponibles.
					</span>{' '}
					{tokens <= 0 && (
						<span
							className="text-sm cursor-pointer underline"
							onClick={() => setOpenBuyTokens(true)}
						>
							Compra mas tokens aquí.
						</span>
					)}
					<div className="flex items-center gap-3 mt-5">
						<button
							disabled={tokens <= 0 || loadingPlaniation}
							style={{
								opacity: tokens <= 0 ? 0.5 : 1,
								cursor: tokens <= 0 ? 'not-allowed' : 'pointer'
							}}
							className="text-sm bg-button-main rounded-lg text-white px-8 py-2 flex items-center gap-2 w-fit"
						>
							<img src={aiIcon} alt="ai icon" />
							Generar planeación
						</button>
						<button
							type="button"
							disabled
							style={{
								opacity: 0.5
								// cursor: tokens <= 0 ? 'not-allowed' : 'pointer'
							}}
							className="text-sm bg-input-bg rounded-lg text-black px-8 py-2 flex items-center gap-2 w-fit"
						>
							<img
								src={settingsIcon}
								alt="ai icon"
								className="w-4"
							/>
							Mas opciones
						</button>
					</div>
				</div>
			) : (
				<div className="flex items-center gap-3 mt-5 bg-white shadow-inner rounded-lg px-8 py-2 w-full">
					<p className="text-xs lg:text-sm text-black text-center w-full">
						<span
							className="text-xs lg:text-sm underline cursor-pointer"
							onClick={() => setOpenLogin(true)}
						>
							Inicia sesión
						</span>{' '}
						o{' '}
						<span
							className="text-xs lg:text-sm underline cursor-pointer"
							onClick={() => setOpenRegister(true)}
						>
							registrate
						</span>{' '}
						para generar una planeación.
					</p>
				</div>
			)}
		</form>
	);
}

export default GenerateForm;
