import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import {
	GoogleAuthProvider,
	createUserWithEmailAndPassword,
	isSignInWithEmailLink,
	sendEmailVerification,
	signInWithEmailAndPassword,
	signInWithEmailLink,
	signInWithPopup
} from 'firebase/auth';
import { auth, googleProvider } from '../firebase';
import Snackbar from 'awesome-snackbar';
import { resNotOk } from '../utils';

const useAccountStore = create(
	devtools((set, get, store) => ({
		user: null,
		myPayments: [],
		tokens: 0,
		clientSecret: null,
		openLogin: false,
		openRegister: false,
		openMyAccount: false,
		openAddPaymentMethod: false,
		openBuyTokens: false,
		openPlaniations: false,
		loadingMyPayments: false,
		loadingPaymetStatus: false,
		loadingLogin: false,
		setUser: (value) => {
			set(() => ({ user: value }));
		},

		setOpenPlaniations: async (value) => {
			await store.getState().closeAllModals();
			set(() => ({ openPlaniations: value }));
		},
		setOpenLogin: async (value) => {
			await store.getState().closeAllModals();
			set(() => ({ openLogin: value }));
		},
		setOpenRegister: async (value) => {
			await store.getState().closeAllModals();
			set(() => ({ openRegister: value }));
		},
		setOpenMyAccount: async (value) => {
			await store.getState().closeAllModals();
			set(() => ({ openMyAccount: value }));
		},
		setOpenAddPaymentMethod: async (value) => {
			await store.getState().closeAllModals();
			set(() => ({ openAddPaymentMethod: value }));
		},
		setOpenBuyTokens: async (value) => {
			await store.getState().closeAllModals();
			set(() => ({ openBuyTokens: value }));
		},
		closeAllModals: () => {
			set(() => ({
				openLogin: false,
				openRegister: false,
				openMyAccount: false,
				openAddPaymentMethod: false,
				openBuyTokens: false,
				openPlaniations: false
			}));
		},
		registerWithGoogle: () => {
			signInWithPopup(auth, googleProvider)
				.then(async (result) => {
					// This gives you a Google Access Token. You can use it to access the Google API.
					const credential =
						GoogleAuthProvider.credentialFromResult(result);
					const token = credential.accessToken;
					// The signed-in user info.
					const user = result.user;

					console.log('🚀 ~ .then ~ user:', user);
					// IdP data available using getAdditionalUserInfo(result)

					// SAVE DATA IN DB
					try {
						const url = process.env.REACT_APP_API_HOST + '/users';
						const response = await fetch(url, {
							method: 'POST',
							credentials: 'include',
							headers: {
								'Content-Type': 'application/json'
							},
							body: JSON.stringify({
								fullName: user.displayName,
								email: user.email,
								googleToken: token,
								accountType: 'GOOGLE'
							})
						});

						if (!response.ok) {
							throw new Error(response.message);
						}
						const userData = {
							...((await response.json()) ?? {}),
							emailVerified: true
						};
						set(() => ({ user: userData }));
						set(() => ({ tokens: userData.tokens }));

						// SAVE GOOGLE TOKEN IN LOCAL STORAGE
						// localStorage.setItem('accessToken', user.accessToken);
						localStorage.setItem('email', user.email);
						localStorage.setItem('accessToken', token);

						new Snackbar('Cuenta creada con exito', {
							style: {
								container: [
									['border-radius', '10px'],
									['background', 'white']
								],
								message: [
									['color', 'green'],
									['font-size', '14px']
								]
							}
						});
					} catch (e) {
						console.error('Server error', e);
						new Snackbar('No se ha podido crear tu cuenta', {
							style: {
								container: [
									['border-radius', '10px'],
									['background', 'white']
								],
								message: [
									['color', 'red'],
									['font-size', '14px']
								]
							}
						});
					}
				})
				.catch((error) => {
					console.error('🚀 ~ devtools ~ error:', error.message);
					// Handle Errors here.
					/* const errorCode = error.code;
					const errorMessage = error.message;
					// The email of the user's account used.
					const email = error.customData.email;
					// The AuthCredential type that was used.
					const credential =
						GoogleAuthProvider.credentialFromError(error);
					// ... */

					new Snackbar('No se ha podido crear tu cuenta', {
						style: {
							container: [
								['border-radius', '10px'],
								['background', 'white']
							],
							message: [
								['color', 'red'],
								['font-size', '14px']
							]
						}
					});
				});
		},
		register: (formValues) => {
			// SEND THIS VALUES TO FIREBASE
			createUserWithEmailAndPassword(
				auth,
				formValues.email,
				formValues.password
			)
				.then(async (userCredential) => {
					// Signed up in firebase
					const user = userCredential.user;

					// SAVE DATA IN DB
					try {
						const url = process.env.REACT_APP_API_HOST + '/users';
						const response = await fetch(url, {
							method: 'POST',
							credentials: 'include',
							headers: {
								'Content-Type': 'application/json'
							},
							body: JSON.stringify({
								fullName: formValues.name,
								email: formValues.email,
								googleToken: user.accessToken,
								accountType: 'EMAIL'
							})
						});

						if (!response.ok) {
							throw new Error(response.message);
						}
						const userData = {
							...((await response.json()) ?? {}),
							emailVerified: false
						};
						set(() => ({ user: userData }));

						// SAVE GOOGLE TOKEN IN LOCAL STORAGE
						// localStorage.setItem('accessToken', user.accessToken);
						localStorage.setItem('email', user.email);

						//SEND AN EMAIL TO CONFIRM THE ACCOUNT
						sendEmailVerification(auth.currentUser, {
							url:
								process.env.REACT_APP_CLIENT_HOST +
								'/&veryEmail=true'
						}).then(() => {
							new Snackbar('Email de verificacion enviado', {
								style: {
									container: [
										['border-radius', '10px'],
										['background', 'white']
									],
									message: [
										['color', 'green'],
										['font-size', '14px']
									]
								}
							});
						});

						new Snackbar('Cuenta creada con exito', {
							style: {
								container: [
									['border-radius', '10px'],
									['background', 'white']
								],
								message: [
									['color', 'green'],
									['font-size', '14px']
								]
							}
						});
					} catch (e) {
						console.error('Server error', e);
						new Snackbar('No se ha podido crear tu cuenta', {
							style: {
								container: [
									['border-radius', '10px'],
									['background', 'white']
								],
								message: [
									['color', 'red'],
									['font-size', '14px']
								]
							}
						});
					}
				})
				.catch((error) => {
					const errorCode = error.code;
					const errorMessage = error.message;

					console.error('Google error', errorCode);
					console.error('Google error', errorMessage);
					new Snackbar('No se ha podido crear tu cuenta', {
						style: {
							container: [
								['border-radius', '10px'],
								['background', 'white']
							],
							message: [
								['color', 'red'],
								['font-size', '14px']
							]
						}
					});
					// ..
				});
			//set((state) => ({ user: newUserData }));
		},
		login: (formValues) => {
			set(() => ({ loadingLogin: true }));
			signInWithEmailAndPassword(
				auth,
				formValues.email,
				formValues.password
			)
				.then(async (userCredential) => {
					// Signed in
					const user = userCredential.user;

					try {
						const url = process.env.REACT_APP_API_HOST + '/login';
						const response = await fetch(url, {
							method: 'POST',
							credentials: 'include',
							headers: {
								'Content-Type': 'application/json',
								Authorization: user.accessToken
							},
							body: JSON.stringify({
								email: formValues.email
							})
						});

						if (!response.ok) {
							throw new Error(response.message);
						}
						const userData = await response.json();

						const newUserData = {
							...(userData.user ?? {}),
							/* googleToken: user.emailVerified
								? user.accessToken
								: '', */
							emailVerified: user.emailVerified
						};

						set(() => ({
							user: newUserData
						}));
						set(() => ({
							tokens: newUserData.tokens
						}));

						// SAVE GOOGLE TOKEN IN LOCAL STORAGE IF THE EAMIL IS VERIFIED
						localStorage.setItem('email', user.email);
						/* if (user.emailVerified) {
							localStorage.setItem(
								'accessToken',
								user.accessToken
							);
						}
 */
						set(() => ({ loadingLogin: false }));
					} catch (e) {
						console.error('Server error', e);
						new Snackbar('No se ha podido ingresar a tu cuenta', {
							style: {
								container: [
									['border-radius', '10px'],
									['background', 'white']
								],
								message: [
									['color', 'red'],
									['font-size', '14px']
								]
							}
						});
						set(() => ({ user: null }));
						set(() => ({ loadingLogin: false }));
					}
				})
				.catch((error) => {
					const errorCode = error.code;
					const errorMessage = error.message;

					console.error('Google error', errorCode);
					console.error('Google error', errorMessage);
					console.error('Google error', error);
					new Snackbar('No se ha podido ingresar a tu cuenta', {
						style: {
							container: [
								['border-radius', '10px'],
								['background', 'white']
							],
							message: [
								['color', 'red'],
								['font-size', '14px']
							]
						}
					});
					set(() => ({ loadingLogin: false }));
				});
		},
		loginWithGoogle: () => {
			signInWithPopup(auth, googleProvider)
				.then(async (result) => {
					const user = result.user;
					const token = user.accessToken;

					try {
						const url = process.env.REACT_APP_API_HOST + '/login';
						const response = await fetch(url, {
							method: 'POST',
							credentials: 'include',
							headers: {
								'Content-Type': 'application/json',
								Authorization: token
							},
							body: JSON.stringify({
								email: user.email
							})
						});

						if (!response.ok) {
							throw new Error(response.message);
						}
						const userData = await response.json();

						const newUserData = {
							...(userData.user ?? {}),
							emailVerified: true
						};

						set(() => ({
							user: newUserData
						}));
						set(() => ({
							tokens: newUserData.tokens
						}));

						// SAVE GOOGLE TOKEN IN LOCAL STORAGE IF THE EAMIL IS VERIFIED
						localStorage.setItem('email', user.email);

						set(() => ({ loadingLogin: false }));
						await store.getState().closeAllModals();
					} catch (e) {
						console.error('Server error', e);
						new Snackbar('No se ha podido ingresar a tu cuenta', {
							style: {
								container: [
									['border-radius', '10px'],
									['background', 'white']
								],
								message: [
									['color', 'red'],
									['font-size', '14px']
								]
							}
						});
						set(() => ({ user: null }));
						set(() => ({ loadingLogin: false }));
					}
				})
				.catch((error) => {
					console.error('🚀 ~ devtools ~ error:', error.message);
					// Handle Errors here.
					/* const errorCode = error.code;
					const errorMessage = error.message;
					// The email of the user's account used.
					const email = error.customData.email;
					// The AuthCredential type that was used.
					const credential =
						GoogleAuthProvider.credentialFromError(error);
					// ... */

					new Snackbar('No se ha podido crear tu cuenta', {
						style: {
							container: [
								['border-radius', '10px'],
								['background', 'white']
							],
							message: [
								['color', 'red'],
								['font-size', '14px']
							]
						}
					});
				});
		},
		loginFromLocalStorage: async (router) => {
			const email = localStorage.getItem('email');

			if (!email) {
				return;
			}

			try {
				set(() => ({ loadingLogin: true }));

				const url = process.env.REACT_APP_API_HOST + '/login';
				const response = await fetch(url, {
					method: 'POST',
					credentials: 'include',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						email: email
					})
				});

				if (!response.ok) {
					resNotOk(response, useAccountStore, useAccountStore.getState().setUser);
				}
				const userData = await response.json();

				set(() => ({
					user: {
						...userData.user,
						emailVerified: userData.emailVerified
					}
				}));
				set(() => ({
					tokens: userData.user.tokens
				}));
				set(() => ({ loadingLogin: false }));

				return userData.user;
			} catch (e) {
				console.error('Server error');

				/* LOGOUT */
				useAccountStore.getState().logout();

				/* localStorage.removeItem('accessToken');
				localStorage.removeItem('email');

				router.navigate('/');

				set(() => ({ user: null }));
				set(() => ({ loadingLogin: false })); */
			}
		},
		loginWithEmailLink: () => {
			if (isSignInWithEmailLink(auth, window.location.href)) {
				// Additional state parameters can also be passed via URL.
				// This can be used to continue the user's intended action before triggering
				// the sign-in operation.
				// Get the email if available. This should be available if the user completes
				// the flow on the same device where they started it.
				let email = window.localStorage.getItem('email');
				if (!email) {
					// User opened the link on a different device. To prevent session fixation
					// attacks, ask the user to provide the associated email again. For example:
					email = window.prompt(
						'Please provide your email for confirmation'
					);
				}
				// The client SDK will parse the code from the link for you.
				signInWithEmailLink(auth, email, window.location.href)
					.then((result) => {
						console.log('🚀 ~ .then ~ result:', result);

						new Snackbar(
							'Email verficado con exito, por favor vuelve a iniciar sesión',
							{
								style: {
									container: [
										['border-radius', '10px'],
										['background', 'white']
									],
									message: [
										['color', 'green'],
										['font-size', '14px']
									]
								}
							}
						);
						// You can access the new user via result.user
						// Additional user info profile not available via:
						// result.additionalUserInfo.profile == null
						// You can check if the user is new or existing:
						// result.additionalUserInfo.isNewUser
					})
					.catch((error) => {
						// Some error occurred, you can inspect the code: error.code
						// Common errors could be invalid email and invalid or expired OTPs.
						console.error('🚀 ~ devtools ~ error:', error);
					});
			}
		},
		logout: async () => {
			try {
				const url = process.env.REACT_APP_API_HOST + '/logout';
				fetch(url, {
					method: 'POST',
					credentials: 'include',
					headers: {
						'Content-Type': 'application/json'
					}
				});
				console.log('Sesión cerrada');
			} catch (e) {
				console.error(e);
			}

			localStorage.removeItem('email');
			set(() => ({ user: null }));
			set(() => ({ tokens: 0 }));
		},
		reSendVerificationEmail: () => {
			sendEmailVerification(auth.currentUser, {
				url: process.env.REACT_APP_CLIENT_HOST + '/&veryEmail=true'
			}).then(() => {
				new Snackbar('Email de verificacion enviado', {
					style: {
						container: [
							['border-radius', '10px'],
							['background', 'white']
						],
						message: [
							['color', 'green'],
							['font-size', '14px']
						]
					}
				});
			});
		},
		getTokens: () => {
			// const accessToken = localStorage.getItem('accessToken');

			set(() => ({ loadingTokens: true }));
			const url = process.env.REACT_APP_API_HOST + '/users/get-tokens';
			fetch(url, {
				method: 'GET',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json'
					// Authorization: accessToken
				}
			})
				.then(async (response) => {
					if (!response.ok) {
						throw new Error(response.message);
					}
					const data = await response.json();

					const newUserData = {
						...useAccountStore.getState().user,
						tokens: data.tokens
					};

					set(() => ({
						tokens: data.tokens
					}));
					set(() => ({ user: newUserData }));
					set(() => ({ loadingTokens: false }));
				})
				.catch((error) => {
					console.error('Server error', error);

					set(() => ({ user: null }));
					set(() => ({ loadingTokens: false }));
				});
		},

		// PAYMENTS
		getMyPayents: async (month = '01', year = '2024') => {
			set(() => ({ loadingMyPayments: true }));
			try {
				const url =
					process.env.REACT_APP_API_HOST +
					`/payments?month=${month}&year=${year}`;

				const response = await fetch(url, {
					method: 'GET',
					credentials: 'include',
					headers: {
						'Content-Type': 'application/json'
						/* Authorization:
							useAccountStore.getState().user.googleToken */
					}
				});

				if (!response.ok) {
					resNotOk(response, useAccountStore, useAccountStore.getState().setUser);
				}

				const data = await response.json();
				console.log('🚀 ~ getmypayents: ~ data:', data);

				set(() => ({ myPayments: data }));

				return data.count;
			} catch (e) {
				set(() => ({ plannings: [] }));
				console.error(e);
			} finally {
				set(() => ({ loadingMyPayments: false }));
			}
		},
		createPaymentIntent: async () => {
			return await fetch(
				process.env.REACT_APP_API_HOST +
					'/payments/create-payment-intent',
				{
					method: 'POST',
					credentials: 'include',
					headers: {
						'Content-Type': 'application/json'
						/* Authorization:
							useAccountStore.getState().user.googleToken */
					},
					body: JSON.stringify({ items: [{ id: 'xl-tshirt' }] })
				}
			)
				.then((res) => res.json())
				.then((data) => {
					return data.clientSecret;
				});
		},
		getSessionStatus: async () => {
			set(() => ({ loadingPaymetStatus: true }));

			const user = store.getState().user;
			const getTokens = store.getState().getTokens;

			if (user) {
				const queryString = window.location.search;
				const urlParams = new URLSearchParams(queryString);
				const sessionId = urlParams.get('session_id');

				return await fetch(
					`${process.env.REACT_APP_API_HOST}/payments/session-status?session_id=${sessionId}`,
					{
						credentials: 'include',
						headers: {
							/* Authorization: user.googleToken */
						}
					}
				)
					.then((res) => res.json())
					.then((data) => {
						getTokens();

						const dataToReturn = data;

						set(() => ({ loadingPaymetStatus: false }));

						return dataToReturn;
					});
			}
		}
	}))
);

export default useAccountStore;
