import './App.css';
import teacher from './assets/teacher.svg';
import GenerateForm from './components/GenerateForm';
import { motion } from 'framer-motion';

function App() {
	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
		>
			<section className="flex min-h-screen mt-[-64px] pb-10 pt-20 sm:pb-0 sm:pt-[64px] items-center gap-20 lg:gap-40">
				<div className="w-full lg:w-1/2">
					<h1 className="text-center font-semibold text-2xl sm:text-4xl md:text-5xl lg:text-4xl lg:text-start">
						Genera tu planeación escolar con inteligencia artificial
					</h1>
					<img
						className="w-3/4 sm:w-1/2 mx-auto my-10 lg:hidden"
						src={teacher}
						alt="Ilutracion de maestra frente pizarron"
					/>
					<GenerateForm />
				</div>
				<div className="w-1/2 hidden lg:block">
					<img
						className="w-full"
						src={teacher}
						alt="Ilutracion de maestra frente pizarron"
					/>
				</div>
			</section>
		</motion.div>
	);
}

export default App;
