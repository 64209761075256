import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import usePlanningsStore from '../../stores/usePlanningsStore';
import arrowLeft from '../../assets/arrow-left.svg';
import download from '../../assets/download.svg';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useAccountStore from '../../stores/useAccountStore';
import { useDebouncedCallback } from 'use-debounce';
import { motion } from 'framer-motion';
import { Export2Doc } from '../../utils';

function Edit() {
	const {
		planiationToEdit,
		getPlaniation,
		updatePlanningContent,
		loadingPlaniation,
		setLoadingPlaniation
	} = usePlanningsStore();
	const { user } = useAccountStore();
	let { id } = useParams();

	const planiationMetadata = useMemo(() => {
		return JSON.parse(
			planiationToEdit?.planiation?.planiation_metadata ?? '{}'
		);
	}, [planiationToEdit]);

	const updatePlanningContentDebounced = useDebouncedCallback(
		(planiationToEdit, data) => {
			updatePlanningContent(planiationToEdit, data);
		},
		1000
	);

	const navigate = useNavigate();

	useEffect(() => {
		setLoadingPlaniation(true);
		if (user?.googleToken) {
			getPlaniation(id);
		}
	}, [user, id, getPlaniation, setLoadingPlaniation]);

	return (
		<motion.div
			className="py-14 editor"
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
		>
			<p
				className="text-base font-semibold text-center flex gap-3 underline cursor-pointer mb-8 w-fit"
				onClick={() => {
					navigate('/');
				}}
			>
				<img src={arrowLeft} alt="ai icon" /> Regresar
			</p>

			{loadingPlaniation ? (
				<div role="status" className="animate-pulse">
					<div className="flex gap-3">
						<div className="h-9 bg-input-bg rounded-full dark:bg-gray-700 w-96 mb-4"></div>
						<div className="h-9 bg-button-main rounded-full dark:bg-gray-700 w-52 mb-4"></div>
					</div>
					<div className="flex gap-3">
						<div className="h-6 bg-input-bg rounded-full dark:bg-gray-700 w-36 mb-4"></div>
						<div className="h-6 bg-input-bg rounded-full dark:bg-gray-700 w-36 mb-4"></div>
					</div>
					<span className="sr-only">Cargando...</span>
				</div>
			) : (
				<>
					<div className="flex items-center gap-3">
						<h1
							className="font-semibold text-3xl"
							style={{
								opacity:
									planiationMetadata?.opportunity?.length > 0
										? 1
										: 0.5,

								fontStyle:
									planiationMetadata?.opportunity?.length ===
									0
										? 'italic'
										: 'normal'
							}}
						>
							{planiationMetadata?.opportunity?.length > 0
								? planiationMetadata?.opportunity + '.'
								: 'Sin problematica u oportunidad...'}
						</h1>
						<button
							style={{
								cursor: 'pointer'
							}}
							className="text-xs bg-button-main rounded-lg text-white px-8 py-2 flex items-center gap-2 w-fit"
							onClick={() => {
								Export2Doc(planiationToEdit);
							}}
						>
							<img src={download} className="h-3" alt="ai icon" />
							Descargar planeación
						</button>
					</div>

					<div className="flex items-center gap-3 mb-8">
						<p
							className="text-base font-light text-center cursor-pointer w-fit"
							onClick={() => {
								navigate(-1);
							}}
						>
							{' '}
							<b className="font-semibold">Nivel: </b>{' '}
							{planiationMetadata?.level}
						</p>
						<p
							className="text-base font-light text-center cursor-pointer w-fit"
							onClick={() => {
								navigate(-1);
							}}
						>
							{' '}
							<b className="font-semibold">Grado: </b>{' '}
							{planiationMetadata?.grade}
						</p>
					</div>
				</>
			)}

			{user && planiationToEdit?.planiation?.planiation && (
				<CKEditor
					editor={ClassicEditor}
					data={
						planiationToEdit?.planiation?.planiation ??
						'Loading planiation...'
					}
					onReady={(editor) => {
						console.log(
							'CKEditor5 React Component is ready to use!',
							editor
						);
					}}
					onChange={(event, editor) => {
						if (planiationToEdit) {
							const data = editor.getData();
							updatePlanningContentDebounced(
								planiationToEdit.planiation,
								data
							);
							console.log({ event, editor, data });
						}
					}}
				/>
			)}
		</motion.div>
	);
}

export default Edit;
