import generating from '../../assets/generating.svg';

function UnderDevelpment() {
	return (
		<div className="h-screen w-screen flex flex-col justify-center items-center gap-6 px-7">
			<h1 className="text-center font-semibold text-2xl sm:text-4xl md:text-5xl lg:text-4xl lg:text-start">
				Estamos en desarrollo...
			</h1>
			<img src={generating} className="" alt="Typing ilustration" />
			<h2 className="max-w-[600px] text-center font-light text-base">
				Si necesitas información sobre el desarrollo, ponte en contacto
				en mi <b className="font-semibold">LinkedIn</b> o entra en mi{' '}
				<b className="font-semibold">sitio web</b>.
			</h2>
			<div className="flex items-center w-full justify-center gap-4">
				<a
					href="https://www.linkedin.com/in/leogarciag/"
					target="_blank"
					rel="noopener noreferrer"
					className="text-button-main underline"
				>
					LinkedIn
				</a>
				<a
					href="leogarciag.com"
					// target="_blank"
					rel="noopener noreferrer"
					className="text-button-main underline"
				>
					Sitio web
				</a>
			</div>
		</div>
	);
}

export default UnderDevelpment;
